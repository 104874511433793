.top-btn {
    /* font-size: 2.4rem; */
  
    color: #fff;
  
    border-radius: 50%;
    position: fixed;
    bottom: 5rem;
    right: 5rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    /* cursor: pointer; */
  
    animation: gototop 1.2s linear infinite alternate-reverse;
  }
  @keyframes gototop {
    0% {
      transform: translateY(-0.5rem);
    }
    100% {
      transform: translateY(1rem);
    }
  }